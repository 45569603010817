import * as React from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

export interface InputPercentageProps extends NumericFormatProps {
  errors?: FieldError;
  message?: string;
}

const InputPercentage = ({
  value,
  onChange,
  placeholder,
  errors,
  message,
  onValueChange,
  suffix,
}: InputPercentageProps): JSX.Element => {
  const [t] = useTranslation(['Others']);
  return (
    <div className="w-full">
      <NumericFormat
        value={value}
        onChange={onChange}
        onValueChange={onValueChange}
        placeholder={placeholder}
        suffix={suffix}
        className="border p-2 appearance-none rounded-full w-full border-secondary font-light text-center"
      />
      <div>
        <small className="text-red text-right mt-1">
          {errors && (message ? message : t('invalid'))}
        </small>
      </div>
    </div>
  );
};

export default InputPercentage;
