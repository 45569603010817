import { useTranslation } from 'gatsby-plugin-react-i18next';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-phone-number-input/style.css';
import React, { useState } from 'react';
import Card from '../Card/Index';
import InputForm from '../Input/Index';
import ExperienceCards from '../ExperienceCards/Index';
import { S3_IMAGES, S3_IMAGES_BUSINESS } from '../../utils/constants';
import Tabs from '../Tabs';
import { StaticImage } from 'gatsby-plugin-image';
import phone from '../../images/fillingSection/phone.svg';
import macbook from '../../images/fillingSection/Macbook.png';
import ellipse from '../../images/fillingSection/ellipse.png';
import macPhone from '../../images/fillingSection/mac-phone.svg';

import comments from '../../images/fillingSection/comments.svg';
import circle from '../../images/fillingSection/circle.png';
import lines from '../../images/fillingSection/triline.png';

import twoWoman from '../../images/fillingSection/two-woman.svg';
import gradient from '../../images/fillingSection/gradient.png';
import Slider, { Settings } from 'react-slick';

const HomeScreen = (): JSX.Element => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const team = [
    {
      image: `${S3_IMAGES_BUSINESS}line-cook.jpg`,
      text: `${t('restaurants:lineCook')}`,
    },
    {
      image: `${S3_IMAGES_BUSINESS}bussier.jpg`,
      text: `${t('restaurants:bussier')}`,
    },
    {
      image: `${S3_IMAGES_BUSINESS}concessions.jpg`,
      text: `${t('restaurants:concessions')}`,
    },
    {
      image: `${S3_IMAGES_BUSINESS}bartender.jpg`,
      text: `${t('restaurants:bartender')}`,
    },
    {
      image: `${S3_IMAGES_BUSINESS}cashier.jpg`,
      text: `${t('restaurants:cashier')}`,
    },
    {
      image: `${S3_IMAGES_BUSINESS}server.jpg`,
      text: `${t('restaurants:server')}`,
    },
    {
      image: `${S3_IMAGES_BUSINESS}cleaner.jpg`,
      text: `${t('restaurants:cleaner')}`,
    },
    {
      image: `${S3_IMAGES_BUSINESS}general-labor.jpg`,
      text: `${t('restaurants:generalLabor')}`,
    },
    {
      image: `${S3_IMAGES_BUSINESS}dishwasher.jpg`,
      text: `${t('restaurants:dishwasher')}`,
    },
  ];

  const cardsText = [
    {
      title: `${t('restaurants:reliableWorkers')}`,
      text: `${t('restaurants:qualifiedWorkers')}`,
      iconName: 'Workers',
    },
    {
      title: `${t('restaurants:noShows')}`,
      text: `${t('restaurants:whatIs')}`,
      iconName: 'Hassle',
    },
    {
      title: `${t('restaurants:hassle')}`,
      text: `${t('restaurants:noMoreWasting')}`,
      iconName: 'Shows',
    },
  ];

  const logosPartners = [
    `${S3_IMAGES}/denverzoo.png`,
    `${S3_IMAGES}/nationalwestern.png`,
    `${S3_IMAGES}/cheyenne.png`,
    `${S3_IMAGES}/mountain.png`,
    `${S3_IMAGES}/paralympic.png`,
    `${S3_IMAGES}/ssa.png`,
    `${S3_IMAGES}/aramark.png`,
    `${S3_IMAGES}/rockies.png`,
    `${S3_IMAGES}/dcpa.png`,
    `${S3_IMAGES}/du.png`,
    `${S3_IMAGES}/redrocks.png`,
  ];

  const settings: Settings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsPartners = {
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: true,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 600,
        settings: {
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const selectable = {
    SHIFT: 'SHIFT',
    MATCH: 'MATCH',
    PROS: 'PROS',
  };

  const [selected, setSelected] = useState(selectable.SHIFT);

  const openCalendly = () => {
    window.open('https://calendly.com/chambadiego/meet-with-diego-chamba?month=', '_blank');
  };

  return (
    <>
      {/* Hero */}

      <div className="font-['SF Pro Rounded'] bg-whiteBlue text-primary">
        <div className="bg-blue-300 lg:px-16">
          <div className="flex flex-col items-center pt-24 pb-10 lg:bg-hero bg-center bg-contain bg-no-repeat">
            <div className="flex justify-center px-7 lg:px-0 ">
              <div className="w-full pb-0 lg:ml-[720px] mt-32">
                <h1 className="font-bold text-4xl lg:text-[40px]">{t('restaurants:power')}</h1>
                <h2 className="font-bold text-4xl lg:text-[40px] pb-5">
                  {t('restaurants:workforce')}
                </h2>
                <p className="pb-5 text-lg font-normal">{t('restaurants:solution')}</p>
                <button
                  onClick={openCalendly}
                  className="px-4 py-2 rounded-full mb-[300px] bg-secondary text-white"
                >
                  {t('book')}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-lightBlue bg-opacity-10 md:flex w-full justify-center p-24 xl:min-h-[650px] relative hidden">
          <div className="w-3/4 flex justify-center self-center items-center">
            <div className="w-1/2">
              <div>
                <h3 className=" lg:text-5xl sm:text-2xl font-bold max-w-lg tracking-wide">
                  {t('restaurants:filling')}{' '}
                  <strong className="text-secondary">{t('restaurants:easier')}</strong>{' '}
                  {t('restaurants:than')}{' '}
                  <strong className="text-secondary">{t('restaurants:ever')}</strong>{' '}
                </h3>
              </div>
              <div>
                <div className="py-5 ">
                  <button
                    onClick={() => setSelected(selectable.SHIFT)}
                    className={`lg:pr-8 md:pr-2 lg:text-lg font-semibold ${
                      selected === selectable.SHIFT ? 'text-secondary' : 'text-softBlue'
                    }`}
                  >
                    {t('restaurants:postShift')}
                  </button>
                  <button
                    onClick={() => setSelected(selectable.MATCH)}
                    className={`lg:px-8 md:px-2 lg:text-lg font-semibold ${
                      selected === selectable.MATCH ? 'text-secondary' : 'text-softBlue'
                    }`}
                  >
                    {t('restaurants:match')}
                  </button>
                  <button
                    onClick={() => setSelected(selectable.PROS)}
                    className={`lg:pl-8 md:pl-2 lg:text-lg font-semibold ${
                      selected === selectable.PROS ? 'text-secondary' : 'text-softBlue'
                    }`}
                  >
                    {t('restaurants:pros')}
                  </button>
                </div>
                <div>
                  <p className={`lg:text-2xl ${selected !== selectable.SHIFT && 'hidden'}`}>
                    {t('restaurants:buildShift')}
                  </p>
                  <p className={`lg:text-2xl ${selected !== selectable.MATCH && 'hidden'}`}>
                    {t('restaurants:shiftPublished')}
                  </p>
                  <p className={`lg:text-2xl ${selected !== selectable.PROS && 'hidden'}`}>
                    {t('restaurants:chambaPro')}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-1/2 ">
              <div className=" ">
                <div className={`relative ${selected !== selectable.SHIFT && 'hidden'}`}>
                  <img className="m-auto " src={macPhone} alt="macPhone" />
                </div>
                <div className={`relative ${selected !== selectable.MATCH && 'hidden'}`}>
                  <div className="">
                    <img className=" m-auto" src={comments} alt="comments" />
                  </div>
                </div>
                <div className={`relative ${selected !== selectable.PROS && 'hidden'}`}>
                  <img className={``} src={twoWoman} alt="two Woman" />
                </div>
              </div>
            </div>

            <img
              className={`absolute bottom-0 right-0 z-0 ${
                selected !== selectable.PROS && 'hidden'
              }`}
              src={gradient}
              alt="gradient"
            />
          </div>
        </div>

        <div className="bg-lightBlue bg-opacity-10 w-full px-6 sm:px-14 md:hidden text-center min-h-[550px] sm:min-h-[700px]">
          <h3 className="text-xl font-bold text-center tracking-wide pt-6">
            {t('restaurants:filling')}{' '}
            <strong className="text-secondary">{t('restaurants:easier')}</strong>{' '}
            {t('restaurants:than')}{' '}
            <strong className="text-secondary">{t('restaurants:ever')}</strong>{' '}
          </h3>
          <div>
            <Slider {...settings}>
              <div>
                <p className={` mt-6 font-semibold text-secondary `}>
                  {' '}
                  {t('restaurants:postShift')}
                </p>
                <div className="relative w-full py-6">
                  <img className="m-auto w-full sm:w-4/6 p-5 pt-6" src={ellipse} alt="ellipse" />
                  <div className=" absolute top-0 w-full ml-8 sm:ml-11  py-6">
                    <img className="w-2/3 sm:w-1/2 m-auto" src={macbook} alt="macbook" />
                  </div>
                  <div className=" absolute top-4 w-full right-14 sm:right-12  my-6">
                    <img className="w-1/4 sm:w-1/5 m-auto" src={phone} alt="Celphone" />
                  </div>
                </div>

                <p className={`text-sm sm:text-xl pb-5`}>{t('restaurants:buildShift')}</p>
              </div>

              <div className="w-full">
                <p className={` mt-6 font-semibold text-secondary `}> {t('restaurants:match')}</p>
                <div className="relative w-full min-h-[200px] sm:min-h-[380px] mt-6">
                  <div className=" w-full  pb-10 pt-4 sm:py-6">
                    <img
                      className="  w-4/6 m-auto max-w-[300px] sm:max-w-[400px]"
                      src={comments}
                      alt="comments"
                    />
                  </div>
                </div>

                <p className={`text-sm sm:text-xl pb-5`}>{t('restaurants:shiftPublished')}</p>
              </div>

              <div className="w-full ">
                <p className={` mt-6 font-semibold text-secondary `}> {t('restaurants:pros')}</p>
                <div className="relative mt-6">
                  <img
                    className={`w-5/6 m-auto sm:w-4/6 max-w-[350px] sm:max-w-none`}
                    src={twoWoman}
                    alt="two Woman"
                  />
                </div>

                <p className={`text-sm sm:text-xl pb-5`}>{t('restaurants:chambaPro')}</p>
              </div>
            </Slider>
          </div>
        </div>
        <div className="flex flex-col items-center pb-20 mt-20">
          <h3 className="text-4xl font-bold pb-10">
            {t('restaurants:build')}
            <span className="text-secondary">{t('restaurants:buildShort')}</span>
            <span>{t('restaurants:buildAnd')}</span>
            <span className="text-secondary">{t('restaurants:buildLong')}</span>
            <span>{t('restaurants:buildEmployment')}</span>
          </h3>
          <div className="flex flex-wrap justify-center">
            {team.map((item, index) => (
              <Card key={index} image={item.image} text={item.text} />
            ))}
          </div>
        </div>
        <div className="flex flex-col justify-center items-center pb-20">
          <h3 className="text-4xl text-center font-bold pb-10">{t('restaurants:experience')}</h3>
          <div className="flex w-[80%] lg:w-[60%] items-center lg:justify-center">
            <div className="flex w-full flex-col md:flex-row self-center justify-center lg:justify-between">
              {cardsText.map((item, index) => (
                <ExperienceCards
                  key={index}
                  title={item.title}
                  text={item.text}
                  iconName={item.iconName}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="flex lg:justify-center w-full items-center flex-col lg:flex-row pb-24">
          <div className="flex flex-col pb-10 lg:justify-center w-[80%]  lg:w-2/5 lg:px-9">
            <h2 className="text-4xl font-bold pb-10">{t('restaurants:transparent')}</h2>
            <p>{t('restaurants:chambaProvides')}</p>
          </div>
          <div className="flex justify-center flex-col w-full sm:w-[90%] lg:w-2/6 px-9">
            <div className=" w-full justify-center bg-white px-7 pt-8 pb-11 rounded-3xl font-bold text-black">
              <InputForm tabSelected={1} isW2 />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center pb-24 w-full lg:flex-row flex-col-reverse">
          <StaticImage className=" " src={'../../images/fillingSection/phone.png'} alt="Celphone" />
          <div className="flex flex-col justify-center w-[80%] lg:w-2/6 px-9 pb-24">
            <h2 className="text-4xl font-bold pb-10">{t('restaurants:matching')}</h2>
            <p>{t('restaurants:chambaHelps')}</p>
          </div>
        </div>

        <div className="flex flex-wrap  justify-center">
          <h2 className="flex w-full justify-center text-4xl font-bold pb-16">
            {t('restaurants:trusted')}
          </h2>
        </div>
        <div className="tablet:px-20 px-4 pb-24">
          <Slider {...settingsPartners}>
            {logosPartners.map((item, index) => (
              <div key={index}>
                <img key={index} className="h-[200px] p-8 " src={item} alt="" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default HomeScreen;
