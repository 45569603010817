import * as React from 'react';
import CurrencyFormat from 'react-currency-format';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface InputCurrencyProps {
  onChange: (value: Values) => void;
  value: number | string | null;
  label: string;
  name: string;
  placeholder: string;
  defaultValue?: string;
  type: InputType;
  errors?: FieldError;
  rows?: number;
  message?: string | undefined;
  customStyle?: string;
}

export interface Values {
  formattedValue: string;
  value: string;
  floatValue: number;
}

const InputCurrency = ({
  name,
  placeholder,
  errors,
  message,
  onChange,
  value,
}: InputCurrencyProps): JSX.Element => {
  const [t] = useTranslation(['Others']);
  return (
    <div className="w-full">
      <CurrencyFormat
        name={name}
        prefix={'$'}
        decimalScale={2}
        decimalSeparator={','}
        thousandSeparator={'.'}
        allowNegative={false}
        value={value}
        onValueChange={onChange}
        placeholder={placeholder}
        className="border p-2 appearance-none rounded-full w-full border-secondary font-light text-center"
      />
      <div>
        <small className="text-red text-right mt-1">
          {errors && (message ? message : t('invalid'))}
        </small>
      </div>
    </div>
  );
};

export default InputCurrency;
