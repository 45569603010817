import React from 'react';
import { useTranslation } from 'react-i18next';

const Input = ({
  label,
  register,
  name,
  validation,
  defaultValue,
  placeholder,
  type,
  errors,
  rows,
  message,
}: IInput): JSX.Element => {
  const [t] = useTranslation(['Others']);
  return (
    <div className="w-full">
      <label className="sr-only">{label}</label>
      {type === 'textarea' ? (
        <textarea
          id={name}
          type={type}
          autoComplete={name}
          defaultValue={defaultValue}
          {...register(name, validation)}
          rows={rows}
          className="mt-4 font-light sm:text-lg appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
          placeholder={placeholder}
        />
      ) : (
        <input
          style={{ webkitAppearance: 'none' }}
          id={name}
          type={type}
          inputMode="numeric"
          autoComplete={name}
          defaultValue={defaultValue}
          {...register(name, validation)}
          className="border p-2 appearance-none rounded-full w-full border-secondary font-light text-center"
          placeholder={placeholder}
        />
      )}
      <div>
        <small className="text-red text-right mt-1">
          {errors && (message ? message : t('invalid'))}
        </small>
      </div>
    </div>
  );
};

export default Input;
