import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import Input from '../InputForm';
import InputCurrency, { Values } from '../InputCurrency';
import InputPercentage from '../InputPercentage';
import { NumberFormatValues } from 'react-number-format';
import { StaticImage } from 'gatsby-plugin-image';

interface ICalculate {
  hourlyWage: Values | null;
  hoursPerShift: string;
  workersPerShift: string;
  shiftPerMonth: string;
  currentAgencyRate?: string;
}
const defaultValues: ICalculate = {
  hourlyWage: null,
  hoursPerShift: '',
  workersPerShift: '',
  shiftPerMonth: '',
  currentAgencyRate: '',
};

interface InputForm {
  isW2?: boolean;
  tabSelected: number;
}

const InputForm = ({ isW2, tabSelected }: InputForm) => {
  const { t } = useTranslation();
  const [totalCost, setTotalCost] = useState<number>(0);
  const [otherAgencyCost, setOtherAgencyCost] = useState<number>(0);
  const [saving, setSaving] = useState<number>(0);
  const rate = isW2 ? 1.42 : 1.3;

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICalculate>({ defaultValues: defaultValues });

  const onSubmit = async (data: ICalculate) => {
    if (data.hourlyWage) {
      if (data.currentAgencyRate) {
        const currencyResult =
          data.hourlyWage.floatValue *
          parseFloat(data.hoursPerShift) *
          parseFloat(data.workersPerShift) *
          parseFloat(data.shiftPerMonth) *
          (parseFloat(data.currentAgencyRate) / 100 + 1);

        const result =
          data.hourlyWage.floatValue *
          parseFloat(data.hoursPerShift) *
          parseFloat(data.workersPerShift) *
          parseFloat(data.shiftPerMonth) *
          rate;

        setTotalCost(parseFloat(result.toFixed(2)));
        setOtherAgencyCost(parseFloat(currencyResult.toFixed(2)));
        setSaving(parseFloat((currencyResult - result).toFixed(2)));
      } else {
        const result =
          data.hourlyWage.floatValue *
          parseFloat(data.hoursPerShift) *
          parseFloat(data.workersPerShift) *
          parseFloat(data.shiftPerMonth) *
          rate;

        setTotalCost(parseFloat(result.toFixed(2)));
      }
    }
  };

  const formatCurrency = React.useCallback((value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  }, []);

  useEffect(() => {
    reset();
  }, [tabSelected]);

  return (
    <div className="pt-5">
      {totalCost !== 0 ? (
        <div className="w-full text-primary">
          {otherAgencyCost !== 0 && saving !== 0 ? (
            <React.Fragment>
              <div className="text-center mb-5">
                <p>{t('restaurants:chambaPrice')}</p>
                <p className="font-bold text-3xl">
                  {formatCurrency(totalCost)} / {t('restaurants:month')}
                </p>
              </div>
              <div className="text-center mb-5">
                <p>{t('restaurants:currentCost')}</p>
                <p className="font-bold text-3xl">
                  {formatCurrency(otherAgencyCost)} / {t('restaurants:month')}
                </p>
              </div>
              <div className="text-center mb-5">
                <p>{t('restaurants:willSave')}</p>
                <p className="font-bold text-3xl">
                  {formatCurrency(saving)} / {t('restaurants:month')}
                </p>
              </div>
            </React.Fragment>
          ) : (
            <div className="text-center mb-5">
              <p>{t('restaurants:chambaPrice')}</p>
              <p className="font-bold text-3xl">
                {formatCurrency(totalCost)} / {t('restaurants:month')}
              </p>
            </div>
          )}
          <div className="w-full flex justify-center py-5">
            <StaticImage src={'../../images/calculate.svg'} alt="Calculate" />
          </div>
          <button
            className="bg-primary text-white w-full py-2 text-base rounded-full font-normal"
            onClick={() => {
              setTotalCost(0);
              setTotalCost(0);
              setOtherAgencyCost(0);
              setSaving(0);
              reset(defaultValues);
            }}
          >
            {t('restaurants:newCalculate')}
          </button>
        </div>
      ) : (
        <form className="w-full text-lg px-0 lg:px-5" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col lg:flex-row justify-center w-full mb-5 items-center">
            <div className="w-full sm:w-1/2 flex justify-center lg:justify-start">
              <p className="pb-5 xl:pb-0">{t('restaurants:hourlyWage')}</p>
            </div>
            <div className="w-full sm:w-1/2 flex justify-end">
              <Controller
                name="hourlyWage"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <InputCurrency
                    name="hourlyWage"
                    label="hourlyWage"
                    type="number"
                    placeholder="e.g. $ 20.00"
                    value={value !== null ? value.formattedValue : ''}
                    onChange={(value: Values) => {
                      onChange(value);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-between w-full mb-5 items-center">
            <div className="w-full sm:w-1/2 flex justify-center lg:justify-start">
              <p className="pb-5 xl:pb-0">{t('restaurants:hoursPerShift')}</p>
            </div>
            <div className="w-full sm:w-1/2 flex justify-end">
              <Controller
                name="hoursPerShift"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <InputPercentage
                    placeholder="e.g. 10.5"
                    value={value}
                    onValueChange={(value: NumberFormatValues) => {
                      onChange(value.floatValue);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-between w-full mb-5  items-center">
            <div className="w-full sm:w-1/2 flex justify-center lg:justify-start">
              <p className="pb-5 xl:pb-0">{t('restaurants:workersPerShift')}</p>
            </div>
            <div className="w-full sm:w-1/2 flex justify-end">
              <Input
                name="workersPerShift"
                label="workersPerShift"
                register={register}
                type="number"
                placeholder="e.g. 5"
                validation={{
                  valueAsNumber: true,
                  required: true,
                }}
              ></Input>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-between w-full mb-5  items-center">
            <div className="w-full sm:w-1/2 flex justify-center lg:justify-start">
              <p className="pb-5 xl:pb-0">{t('restaurants:shiftPerMont')}</p>
            </div>
            <div className="w-full sm:w-1/2 flex justify-end">
              <Input
                name="shiftPerMonth"
                label="shiftPerMonth"
                register={register}
                type="number"
                placeholder="e.g. 3"
                validation={{
                  valueAsNumber: true,
                  required: true,
                }}
              ></Input>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-between w-full mb-5 items-center">
            <div className="w-full sm:w-1/2 flex justify-center lg:justify-start">
              <p className="pb-5 xl:pb-0">{t('restaurants:currentAgencyRate')}</p>
            </div>
            <div className="w-full sm:w-1/2 flex justify-end">
              <Controller
                name="currentAgencyRate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputPercentage
                    placeholder={`${isW2 ? 'e.g. 42%' : 'e.g. 30%'}`}
                    value={value}
                    suffix={'%'}
                    onValueChange={(value: NumberFormatValues) => {
                      onChange(value.floatValue);
                    }}
                  />
                )}
              />
            </div>
          </div>

          <button
            className="bg-primary text-white w-full py-2 rounded-full font-normal "
            type="submit"
          >
            {t('restaurants:calculate')}
          </button>
        </form>
      )}
    </div>
  );
};

export default InputForm;
