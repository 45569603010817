/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { graphql, HeadFC } from 'gatsby';
import SEO from '../components/SEO';
import Header from '../components/Header';
import Body from '../components/Body';
import { Toaster } from 'react-hot-toast';
import Footer from '../components/Footer';
import { StaticImage } from 'gatsby-plugin-image';

const IndexPage = (): JSX.Element => {
  return (
    <>
      <StaticImage
        width={150}
        style={{ position: 'absolute', visibility: 'hidden' }}
        src="../images/SEO-logo.jpg"
        alt="Logo"
        title="Logo Chamba"
      />
      <Header />
      <Body />
      <Footer />
      <Toaster />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head: HeadFC = () => {
  return <SEO />;
};
