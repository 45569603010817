import React from 'react';
import Icon from '../../assets/icons';

interface IExpCards {
  title: string;
  text: string;
  iconName: string;
}

const ExperienceCards = ({ title, text, iconName }: IExpCards) => {
  return (
    <div className="flex self-start justify-center w-full flex-col pb-7 items-center">
      <Icon className="pb-5" icon={iconName} size={105} color="text-primary" />

      <p className="font-bold text-xl pb-5">{title}</p>
      <p className="flex text-center">{text}</p>
    </div>
  );
};

export default ExperienceCards;
