import React from 'react';

interface ITeam {
  image: string;
  text: string;
}
const Card = ({ image, text }: ITeam) => {
  return (
    <div className="w-full sm:w-1/3 lg:w-1/4 m-3">
      <div
        className="flex items-end w-full h-64 bg-cover bg-center bg-no-repeat  rounded-lg cursor-pointer hover:scale-110 transition duration-300 ease-in-out"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <p className="text-white font-bold text-2xl pl-5 pb-5">{text}</p>
      </div>
    </div>
  );
};

export default Card;
